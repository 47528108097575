import styled, { StyledProps } from 'styled-components'
import { IPropHeight } from '@ucheba/utils/helpers/components/prop.types'
import { Picture } from '@ucheba/ui/components/Picture'

interface IProps extends IPropHeight {
  maxHeight: string
  maxWidth: string
}
const Image = styled(Picture)<StyledProps<IProps>>`
  height: ${(props) => props.height};
  max-height: ${(props) => props.maxHeight};
  max-width: ${(props) => props.maxWidth};
`

const Block = styled.div`
  flex-shrink: 0;
`

export default { Block, Image }
