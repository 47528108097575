import { useNotice } from '@ucheba/ui/components/Notice/bll'
import { useCallback, useMemo, useState } from 'react'
import { stringifyUrl } from '@ucheba/utils/helpers/router'
import { useSelector } from 'react-redux'
import { siteNameSelectors } from '@ucheba/store/siteName'

interface IUseRegDialog {
  (): {
    isOpen: boolean
    onClose: () => void
    onOpen: (data?: Record<string, string | number>) => void
    params: Record<string, string | number>
  }
}

export const useRegDialog: IUseRegDialog = () => {
  const dialog = useNotice('regDialogId')
  const [params, setParams] = useState<Record<string, string | number>>({})

  const onOpen = useCallback(
    (data?: Record<string, string | number>) => {
      if (data) {
        setParams(data)
      } else {
        setParams({})
      }

      dialog.addNotice()
    },
    [dialog]
  )

  return {
    isOpen: !!dialog.open,
    onClose: dialog.removeNotice,
    onOpen,
    params,
  }
}

interface IUseMessengersHref {
  (params: Record<string, string | number>): {
    vkHref: string
    tgHref: string
    waHref: string
  }
}

export const useMessengersHref: IUseMessengersHref = (params) => {
  const siteName = useSelector(siteNameSelectors.siteName)

  const vkHref = useMemo(() => {
    return stringifyUrl('/events/vk-subscribe/registration', {
      update: {
        ...params,
        siteName,
      },
    })
  }, [params, siteName])

  const tgHref = useMemo(() => {
    return stringifyUrl('/events/tg-subscribe/registration', {
      update: {
        ...params,
        siteName,
      },
    })
  }, [params, siteName])

  const waHref = useMemo(() => {
    return stringifyUrl('/events/whatsapp-subscribe/registration', {
      update: {
        ...params,
        siteName,
      },
    })
  }, [params, siteName])

  return {
    vkHref,
    tgHref,
    waHref,
  }
}
