import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { eventsMySelectors, eventsMyThunks } from '@ucheba/store/events/my'
import { removeNoticeCookie, useNotice } from '@ucheba/ui/components/Notice/bll'
import { ELoading } from '@ucheba/store/utils/response/types'
import { eventsIdSelectors, eventsIdThunks } from '@ucheba/store/events/id'
import {
  objectToQueryString,
  queryStringToObject,
  stringifyUrl,
} from '@ucheba/utils/helpers/router'
import { coreQuery } from '@ucheba/utils/constants/queries'
import { authSelectors } from '@ucheba/store/auth'
import { useRouter } from 'next/router'
import { getParsedCookies } from '@ucheba/utils/helpers/core'
import { EMessengerType } from '@ucheba/store/profile/types'
import { profileSelectors } from '@ucheba/store/profile'
import dayjs from '@ucheba/utils/helpers/date/dayjs'
import { IEventsSearchItem } from '@ucheba/store/events/search/types'
import { getVisitInfo } from '@ucheba/utils/hooks/useVisitsTracker'
import { UCHEBA_SITE_NAME } from '@ucheba/utils/constants/core'
import { useDialog } from '@ucheba/ui/components/Dialog/bll'
import { INotice } from '@ucheba/ui/components/Notice/types'
import { IDialog } from '@ucheba/ui/components/Dialog/types'
import {
  successRegisterOfflineEventNoticeId,
  successRegisterOnlineNotStartedEventNoticeId,
  successRegisterOnlineStartedEventNoticeId,
} from '../../pages/for-abiturients/events/index/constants'
import data from './data.json'
import { hourInMs, institutionPath } from './contants'
import {
  EEventFormat,
  EEventRegisteredStatus,
  IEventsListItemProps,
  IUseEventsListItemCore,
  IUseFinishedEvents,
} from './types'
import { notAllowedMessegerNoticeId } from '../EventPages/components/EventNotices/EventNoticeNotAllowedMessage/constants'
import { ENoticePermissionQueryType } from '../VkPermissionNotice/types'
import {
  parentForm,
  parentFormDialogRequestId,
  parentFormDialogSuccessId,
  uchebaForm,
  institutionForm,
} from '../ParentFormDialog/constants'

export const useSpecialityHref = (
  id: IEventsListItemProps['id'],
  pathname: string
): string => {
  return useMemo(() => `${pathname}/${id}`, [id, pathname])
}

export const useEventsListItemCore: (props) => {
  onClickRegistration: () => Promise<void>
  directVkStreamUrl: string | null | undefined
  description: string | null
  isEventStartedOrHour: boolean
  messengersDialog: IDialog
  buttonData: {
    eventRegisteredStatus: EEventRegisteredStatus
    buttonText: string
  }
  notices: {
    onlineEventStartedNotice: INotice
    offlineEventNotice: INotice
    onlineEventNotStartedNotice: INotice
    notAllowedMessegerNotice: INotice
  }
  institution: null | {
    name: string
    logo: string
    location: {
      id: number | string
      name: string
    }
    href: string
  }
  onlineEventStartedNotice: INotice
  registersPending: boolean
  offlineEventNotice: INotice
  href: string
  onlineEventNotStartedNotice: INotice
} = (props) => {
  const {
    id,
    startAt,
    institution: institutionSrc,
    formats: formatsSrc,
    description,
    streamUrl,
    pathname,
    withExternalRegistration,
    externalUrl,
    directVkStreamUrl,
    isAllowedMess,
    rubric,
    branding,
    exam,
  } = props

  const dispatch = useDispatch()
  const isAuth = useSelector(authSelectors.isAuth)
  const router = useRouter()
  const eventsIdLoading = useSelector(eventsIdSelectors.loading)
  const eventsMyLoading = useSelector(eventsMySelectors.loading)
  const userRegisteredEvents = useSelector(eventsMySelectors.entity)
  const profile = useSelector(profileSelectors.entity)
  const parentFormDialog = useDialog(parentFormDialogRequestId + id)
  const messengersDialog = useDialog(parentFormDialogSuccessId + id)

  /** Стейт с событиями, на регистрацию которых отправили */
  const [registersPending, setRegistersPending] = useState(false)

  const onlineEventStartedNotice = useNotice(
    successRegisterOnlineStartedEventNoticeId + id
  )
  const onlineEventNotStartedNotice = useNotice(
    successRegisterOnlineNotStartedEventNoticeId + id
  )
  const offlineEventNotice = useNotice(successRegisterOfflineEventNoticeId + id)
  const notAllowedMessegerNotice = useNotice(notAllowedMessegerNoticeId + id)

  /** Перезапрашиваем список мероприятий, на которые зарегистрирован пользователь */
  useEffect(() => {
    if (eventsIdLoading === ELoading.fulfilled && registersPending && isAuth) {
      dispatch(eventsMyThunks.fetch({}))
    }
  }, [dispatch, eventsIdLoading, registersPending, isAuth])

  // Событие начлось или осталось меньша часа до начала
  const isEventStartedOrHour = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return dayjs(startAt) - dayjs() < hourInMs
  }, [startAt])

  // Событие начлось или осталось меньше 10 минут
  const isEventStartedOrTenMinutes = useMemo(() => {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dayjs(startAt) - dayjs() < 10 * 60 * 1000
    )
  }, [startAt])

  /** Определяем статус зарегистрирован ли пользователь на событие */
  const eventRegisteredStatus = useMemo(() => {
    if (userRegisteredEvents?.includes(id)) {
      if (isEventStartedOrTenMinutes && streamUrl) {
        return EEventRegisteredStatus.watchBroadcast
      }
      return EEventRegisteredStatus.isRegistered
    }
    return EEventRegisteredStatus.isNotRegistered
  }, [id, isEventStartedOrTenMinutes, userRegisteredEvents, streamUrl])

  /** Определяем тип нотиса относительно статуса регистрации на мероприятие */
  const definedNotice = useMemo(() => {
    if (formatsSrc.some((item) => item.value === EEventFormat.online)) {
      if (isEventStartedOrTenMinutes && streamUrl) {
        return {
          notice: onlineEventStartedNotice,
          id: successRegisterOnlineStartedEventNoticeId,
        }
      }
      if (
        !formatsSrc.some((item) => item.value === EEventFormat.offline) ||
        !isEventStartedOrTenMinutes
      ) {
        return {
          notice: onlineEventNotStartedNotice,
          id: successRegisterOnlineNotStartedEventNoticeId,
        }
      }
    }
    return {
      notice: offlineEventNotice,
      id: successRegisterOfflineEventNoticeId,
    }
  }, [
    formatsSrc,
    isEventStartedOrTenMinutes,
    offlineEventNotice,
    onlineEventNotStartedNotice,
    onlineEventStartedNotice,
    streamUrl,
    isAllowedMess,
    notAllowedMessegerNotice,
  ])

  const store = useStore()

  /** Если пользователь авторизован, то регистрируем на мероприятие запросом в апи, если нет,
   * то отправляем на страницу регистрации на мероприятие */
  const register = useCallback(async () => {
    // if (eventRegisteredStatus === EEventRegisteredStatus.isNotRegistered) {
    setRegistersPending(true)

    removeNoticeCookie({ id: definedNotice.id + id })

    const profileStore = store.getState().profile.entity

    if (withExternalRegistration && externalUrl) {
      router.push(stringifyUrl(`/events/${id}/registration`, {}))
    } else if (isAuth) {
      await dispatch(
        eventsIdThunks.register({ data: { id, visit: getVisitInfo(UCHEBA_SITE_NAME) } })
      )

      if (rubric?.id === 4) {
        if (profileStore && !profileStore.secondPhone) {
          parentFormDialog.openDialog()
        } else {
          setTimeout(() => {
            messengersDialog.openDialog()
          }, 600)
        }
      } else if (institutionSrc && branding) {
        setTimeout(() => {
          messengersDialog.openDialog()
        }, 600)
      }

      if (!isAllowedMess) {
        notAllowedMessegerNotice.addNotice()
      } else {
        definedNotice.notice.addNotice()
      }
    } else {
      // setNoticeCookie({ id: definedNotice.id + id })

      const queryObject = queryStringToObject(router.asPath)

      if (rubric?.id === 4) {
        queryObject[parentForm] = id
      }

      // if (rubric?.id === 4 && !exam) {
      //   queryObject[uchebaForm] = id
      // }

      if (institutionSrc && branding) {
        queryObject[institutionForm] = id
      }

      const newPath = objectToQueryString({
        [ENoticePermissionQueryType.n_m_block]: 1,
        ...queryObject,
      })

      const newParams = {
        [coreQuery.redirect]: `${router.asPath.split('?')[0]}?${newPath}`,
        definedNotice: definedNotice.id + id,
      }

      router.push(
        stringifyUrl(`/events/${id}/registration`, {
          set: {
            ...newParams,
          },
        })
      )
    }
  }, [
    definedNotice.id,
    definedNotice.notice,
    id,
    store,
    withExternalRegistration,
    externalUrl,
    isAuth,
    router,
    dispatch,
    rubric?.id,
    institutionSrc,
    branding,
    isAllowedMess,
    parentFormDialog,
    messengersDialog,
    notAllowedMessegerNotice,
  ])

  // проверяем дал ли разрешение писать в мессенджеры или нет и в зависимости от этого показываем нотис
  const showNotice = useCallback(() => {
    const noticesCookies =
      getParsedCookies().notices && JSON.parse(getParsedCookies().notices)

    if (profile) {
      const { messengers } = profile

      const isAllow = messengers.some(
        ({ messengerType, isAllowed }) =>
          isAllowed &&
          (messengerType === EMessengerType.vk ||
            messengerType === EMessengerType.telegram)
      )

      // если есть куки нотиса, то показываем нотис
      if (
        noticesCookies &&
        noticesCookies.length &&
        noticesCookies.find((el) => el.includes(id)) &&
        (eventRegisteredStatus === EEventRegisteredStatus.isRegistered ||
          eventRegisteredStatus === EEventRegisteredStatus.watchBroadcast)
      ) {
        if (!isAllow) {
          notAllowedMessegerNotice.addNotice()

          removeNoticeCookie({ id: definedNotice.id + id })
        } else {
          definedNotice.notice.addNotice()

          removeNoticeCookie({ id: definedNotice.id + id })
        }
      }
    }
  }, [
    definedNotice.id,
    definedNotice.notice,
    profile,
    id,
    notAllowedMessegerNotice,
    eventRegisteredStatus,
  ])

  useEffect(() => {
    showNotice()
  }, [eventRegisteredStatus, id, profile])

  /** Когда отправка запросов на регистрацию закончится, обнуляем статусы событий
   * и показываем уведомление о успешной регистрации */
  useEffect(() => {
    if (eventsIdLoading !== ELoading.pending && eventsMyLoading === ELoading.fulfilled) {
      setRegistersPending(false)
    }
  }, [eventsIdLoading, eventsMyLoading])

  const href = useSpecialityHref(id, pathname)

  const institution = useMemo(
    () =>
      institutionSrc && {
        name: institutionSrc.name,
        logo: institutionSrc.logo,
        href: `${institutionPath}/${institutionSrc.id}`,
        location: institutionSrc.location,
      },
    [institutionSrc]
  )

  /** Текст кнопки относительно статуса регистрации на событие */
  const buttonText = useMemo(() => {
    return data[eventRegisteredStatus]
  }, [eventRegisteredStatus])

  /** Данные по кнопке */
  const buttonData = useMemo(() => {
    return {
      eventRegisteredStatus,
      buttonText,
    }
  }, [buttonText, eventRegisteredStatus])

  return {
    href,
    institution,
    description,
    onClickRegistration: register,
    buttonData,
    onlineEventStartedNotice,
    onlineEventNotStartedNotice,
    offlineEventNotice,
    registersPending,
    notices: {
      onlineEventNotStartedNotice,
      onlineEventStartedNotice,
      offlineEventNotice,
      notAllowedMessegerNotice,
    },
    isEventStartedOrHour,
    directVkStreamUrl,
    messengersDialog,
  }
}

interface IUseEventsListItemDate {
  (props: IEventsSearchItem): {
    format: string | null
    date: string | null
  }
}

export const useEventsListItemDate: IUseEventsListItemDate = (props) => {
  const { formats: formatsSrc, startAt, endAt } = props || {}

  // событие длится меньше 24 часа
  const isEventMore24Hours = useMemo(() => {
    const hours = hourInMs * 24 // час в миллисекундах

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return dayjs(endAt) - dayjs(startAt) < hours
  }, [startAt, endAt])

  const format = useMemo(() => {
    if (
      formatsSrc?.some((val) => val.value === EEventFormat.online) &&
      formatsSrc?.some((val) => val.value === EEventFormat.offline)
    ) {
      return 'Офлайн с трансляцией'
    }
    return formatsSrc ? formatsSrc[0].name : null
  }, [formatsSrc])

  // получение таймзоны пользователя
  const getTimeZone = useMemo(() => {
    const offset = (new Date().getTimezoneOffset() * -1) / 60 - 3 // получаем таймзону

    // eslint-disable-next-line no-nested-ternary
    return `МСК ${offset > 0 ? `+${offset}` : offset < 0 ? `${offset}` : ''}`
  }, [])

  // todo: Необходимо оптимизировать - рефакторинг
  // получение даты и времения мероприятия в списке мероприятий
  const date = useMemo(() => {
    const startDate = dayjs(startAt).format(`D MMM YYYY HH:mm`).split(' ')
    const endDate = endAt ? dayjs(endAt).format(`D MMM YYYY HH:mm`).split(' ') : null
    const dateToday = dayjs().format(`D MMM YYYY HH:mm`).split(' ')
    const startLongDate = dayjs(startAt).format(`D MMMM YYYY HH:mm`).split(' ')

    if (!endDate) {
      if (dateToday[2] !== startDate[2]) {
        return startAt && dayjs(startAt).format(`D MMM YYYY, HH:mm ${getTimeZone}`)
      }

      return startAt && dayjs(startAt).format(`D MMMM, HH:mm ${getTimeZone}`)
    }

    if (endDate[2] !== startDate[2]) {
      if (startDate[2] === dateToday[2]) {
        return `${startDate[0]} ${startDate[1]} — ${endDate[0]} ${endDate[1]} ${endDate[2]}`
      }

      return `${startDate[0]} ${startDate[1]} ${startDate[2]}–${endDate[0]} ${endDate[1]} ${endDate[2]}`
    }

    if (endDate[1] !== startDate[1]) {
      if (isEventMore24Hours) {
        return `${startLongDate[0]} ${startLongDate[1]}, ${startDate[3]}–${endDate[3]} ${getTimeZone}`
      }

      return `${startDate[0]} ${startDate[1]} — ${endDate[0]} ${endDate[1]}`
    }

    if (endDate[0] !== startDate[0]) {
      if (isEventMore24Hours) {
        return `${startLongDate[0]} ${startLongDate[1]}, ${startDate[3]}–${endDate[3]} ${getTimeZone}`
      }

      return `${startDate[0]}–${endDate[0]} ${startLongDate[1]}`
    }

    return `${startLongDate[0]} ${startLongDate[1]}, ${startDate[3]}–${endDate[3]} ${getTimeZone}`
  }, [startAt, endAt, isEventMore24Hours, getTimeZone])

  return {
    format,
    date,
  }
}

/** Получаем индекс первого завршенного мероприятия и возвращаем тру ли фолс в зависимости от условий */
export const useFinishedEvents: IUseFinishedEvents = (events) => {
  const index = useMemo(() => {
    return events.findIndex((el) => el.isFinished)
  }, [events])

  return useCallback(
    (key) => {
      if (index === 0) return false

      return index === key
    },
    [index]
  )
}
