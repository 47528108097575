export const parentForm = 'parent_form'
export const uchebaForm = 'uchebaForm'
export const institutionForm = 'institutionForm'

export const parentFormDialogRequestId = 'parentFormDialogRequestId'

export const parentFormDialogSuccessId = 'parentFormDialogSuccessId'

export const socialText = {
  title: 'Регистрация подтверждена',
  description:
    'За день до мероприятия мы пришлём вам напоминание, а за 15 минут до начала - ссылку на подключение.<br/>&nbsp;<br/> Выберите мессенджер, куда вам отправить Гид абитуриента',
  description2:
    'За день до мероприятия мы пришлем вам напоминание, а за 15 минут до начала - ссылку на подключение.<br/>&nbsp;<br/> Выберите мессенджер, куда вам отправить ссылку на подключение к трансляции',
}

export const studentFormText =
  'Для участия в мероприятии понадобятся данные твоего родителя. Обрати внимание, прохождение профориентации и диагностики ЕГЭ доступны только старшеклассникам, пришедшим вместе с родителями.'

export const parentFormText =
  'Для участия в мероприятии понадобятся данные вашего ребенка. Приходите вместе с ним, чтобы получить максимум пользы.'
