import React, { FC, memo } from 'react'
import Link from 'next/link'
import SC from '../base/ExternalLogo.styles'
import { IExternalLogoProps } from '../types'

const { Block, Image } = SC

/** Внешний логотип */
const ExternalLogo: FC<IExternalLogoProps> = (props) => {
  const { logo, maxHeight, maxWidth, href, alt = '', target, height } = props

  const image = (
    <Block>
      <Image
        alt={alt}
        fallbackUrl={logo?.fallbackUrl}
        webpUrl={logo?.webp}
        height={height}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        loading='lazy'
      />
    </Block>
  )

  if (href) {
    return (
      <Link href={href} passHref target={target}>
        {image}
      </Link>
    )
  }

  return image
}

export { ExternalLogo }
export default memo(ExternalLogo)
